import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
        meta:{title:'首页'}
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta:{title:'404'}
    },
    { path: '*', redirect: '/404' }
]

const router= new Router({
    routes,
    linkActiveClass:'active',
    linkExactActiveClass:'exact-active'
})
export default router
