const pageTitle = "🐏了个🐏"

export default function getPageTitle(title) {
    let lasttitle = '';
    if (!title) {
        lasttitle = title
    } else {
        lasttitle = title + '-' + pageTitle
    }
    return lasttitle
}
